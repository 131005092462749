import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const RootLayout = ({ children }) => {
    const [state, setState] = useState(false)
    const location = useLocation()
    const navigation = useNavigate()
    useEffect(() => {
        const lastPageUrl = localStorage.getItem('lastPageUrl')
        if (!lastPageUrl || state) {
            return
        }
        setState(true)
        if (lastPageUrl == location.pathname) {
            return
        }
        setTimeout(() => {
            navigation(lastPageUrl)
        }, 10)
    }, [])
    useEffect(() => {
        localStorage.setItem('lastPageUrl', location.pathname)
    }, [location])

    return children
}

export default RootLayout
