import en_US from 'antd/locale/en_US'
import fr_FR from 'antd/locale/fr_FR'
import zhCN from 'antd/locale/zh_CN'
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en_us from "./en-us.json";
import fr from "./fr.json";
import zh_CN from "./zh.json";

import 'dayjs/locale/en'
import 'dayjs/locale/zh-cn'

export const languages = {
    "zh-CN": {
        translation: zh_CN,
    },
    "en-US": {
        translation: en_us,
    },
    "fr_FR": {
        translation: fr,
    }
}

export const locales = {
    'zh-CN': zhCN,
    'en-US': en_US,
    "fr_FR": fr_FR,
}

export const languageitems = [
    { key: 'zh-CN', label: '中文' },
    { key: 'en-US', label: 'English' },
    { key: 'fr_FR', label: 'Français' },
]

export const languageitemsObj = {
    'zh-CN': '中文',
    'en-US': 'English',
    'fr_FR': 'Français',
}

const lag = window.navigator.language == 'zh' ? 'zh-CN' : window.navigator.language

export const defaultLanguage = localStorage.getItem('language') || lag || 'zh-CN'

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: defaultLanguage,
        lng: defaultLanguage,
        debug: false,
        resources: languages,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });

export default i18n;
