import { useMutation } from '@tanstack/react-query'
// import { message } from 'antd'
import { Toast } from 'antd-mobile'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { HashRouter, BrowserRouter } from 'react-router-dom'

import { getUserInfo } from '@/services'
import useAppContext from '@/useAppContext'

import Layout from './pages/Layout'
import Router from './pages/Router'
// import { routes } from './pages/routes'

const MainRouter = process?.env?.isPro ? BrowserRouter : HashRouter

const App = () => {
    const { t } = useTranslation()
    const { context, dispatch } = useAppContext.getContext()
    const { token, language, loading } = context
    useEffect(() => {
        console.log(process?.env)
        document.title = t('网站标题')
    }, [language])
    const mutation = useMutation({
        mutationFn: getUserInfo,
        onSuccess: (data: any) => {
            dispatch({ user: data, loading: false, isLogin: true })
        },
        onError: (msg: string) => {
            Toast.show(msg)
            localStorage.removeItem(window.tokenKey)
            sessionStorage.removeItem(window.tokenKey)
            dispatch({ token: undefined, loading: false, isLogin: false })
        },
    })
    useEffect(() => {
        dispatch({ loading: false, isLogin: false })
        if (!token) {
            return dispatch({ loading: false, isLogin: false })
        }
        // let menus = initCycleMenu(routes, ["XTGL2", "XTGL", "XTGL-JSGL"], true)
        // dispatch({ user: {}, loading: false, isLogin: true, menus })
        dispatch({ loading: true, isLogin: false })
        mutation.mutate()
        let out = false
        const logOut = () => {
            if (!out) {
                Toast.show('token无效或已过期，请重新登录')
            }
            out = true
            localStorage.removeItem(window.tokenKey)
            sessionStorage.removeItem(window.tokenKey)
            dispatch({ token: undefined, loading: false, isLogin: false })
        }
        window.addEventListener('tokenOut', logOut, false)
        return () => window.removeEventListener('tokenOut', logOut, false)
    }, [token])
    if (loading) {
        return null
    }
    return (
        <MainRouter>
            <Layout>
                <Router />
            </Layout>
        </MainRouter>
    )
}

export default App
