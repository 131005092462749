(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"), require("seamless-immutable"), require("react-router-dom"), require("axios"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-dom", "seamless-immutable", "react-router-dom", "axios"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("react-dom"), require("seamless-immutable"), require("react-router-dom"), require("axios")) : factory(root["react"], root["react-dom"], root["seamless-immutable"], root["react-router-dom"], root["axios"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__7111__, __WEBPACK_EXTERNAL_MODULE__5406__, __WEBPACK_EXTERNAL_MODULE__6128__, __WEBPACK_EXTERNAL_MODULE__3300__) => {
return 