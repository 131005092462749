import { db } from '@/database/dexie'

// 创建一个新的 worker
const myWorker = new Worker('/worker.js')

// 监听来自 worker 的消息
myWorker.onmessage = function (e) {
    const { data } = e
    console.log('获取', e.data)
    if (data?.type == 'bookCache') {
        const bookId = data.data.book._id
        const id = data.data.id
        db.booksContent.put({ ...data.data, bookId, uid: `${bookId}_${id}` }, `${bookId}_${id}`)
    }
}

export const postMessage = (type, data, callBack?: Function) => {
    const cbKey = Date.now()
    myWorker.postMessage({ type, data, cbKey })

    if (callBack) {
        const cb = (e) => {
            if (e.data?.cbKey == cbKey) {
                callBack(e.data)
                myWorker.removeEventListener('message', cb)
            }
        }
        myWorker.addEventListener('message', cb)
    }
    return cbKey
}

export default myWorker
