import Dexie, { Table } from 'dexie'

export interface Books {
    _id?: string
    title?: string
    createdAt?: number
}

export interface ReadLogs {
    _id: string
    id: number
    title?: string
    bookId?: string
    bookTitle?: string
    createdAt?: number
}

export interface BooksContent {
    uid: string
    id: number
    title?: string
    bookId?: string
}

// Dexie.delete('myDatabase');

export class DataBaseClasse extends Dexie {
    // 'friends' is added by dexie when declaring the stores()
    // We just tell the typing system this is the case
    favorites!: Table<Books>
    books!: Table<Books>
    readLogs!: Table<ReadLogs>
    booksContent!: Table<BooksContent>
    constructor() {
        super('myDatabase')
        this.version(27).stores({
            favorites: '&_id, title, createdAt',
            books: '&_id, title, createdAt',
            readLogs: '&_id, id, title, createdAt, bookId, bookTitle',
            booksContent: '&uid, id, title, bookId',
        })
    }
}

export const db = new DataBaseClasse()
