import { defaultLanguage, locales } from '@pn/components/src/locale'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { keepPreviousData, QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { ConfigProvider } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom/client'
import { RecoilRoot } from 'recoil'

import useAppContext from '@/useAppContext'
import { useData } from '@/utils/hooks'

import App from './App'
import './worker'

import './main.less'

window.tokenKey = 'tokenApp'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            gcTime: 1000 * 60 * 60, // 数据垃圾回收时间 默认 1000 * 60 * 5 5分钟
            staleTime: 1000 * 60, // 重新获取数据的时间间隔 默认0
            retry: 2, // 失败重试次数 默认 3次
            refetchOnWindowFocus: false, // 窗口获得焦点时重新获取数据
            placeholderData: keepPreviousData,
            // keepPreviousData: false,
            //   retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
        },
    },
})

const persister = createSyncStoragePersister({
    storage: window.localStorage,
})

const Root = () => {
    const initValue = {
        token: sessionStorage.getItem(window.tokenKey) || localStorage.getItem(window.tokenKey),
        isTraditional: localStorage.getItem('isTraditional') == 'false' ? false : true,
        user: {},
        menus: [],
        isLogin: false,
        loading: true,
        language: defaultLanguage,
        theme: localStorage.theme || '#00d39a',
        fontSize: Number(localStorage.getItem('fontSize') || 22),
        isDark: localStorage.getItem('isDark') == 'true',
        backgroundColor: localStorage.getItem('backgroundColor') || '#e7e0d0',
    }
    const dataRef = useRef<any>({})
    const [data, setData] = useData(initValue)
    dataRef.current = data
    const { theme } = data
    let context = { context: data, dispatch: setData }
    useEffect(() => {
        dayjs.locale(data.language)
    }, [data.language])
    useEffect(() => {
        localStorage.theme = theme
        let meta = document.querySelector('meta[name="theme-color"]')
        if (meta) {
            meta.setAttribute('content', theme)
        }
        document.documentElement.style.setProperty('--adm-color-primary', theme)
        // document.documentElement.setAttribute(
        //     'data-prefers-color-scheme',
        //     'dark'
        // )
        // const styles = `
        //     body{color:${theme}}
        // `
        // let style = document.createElement("style")
        // style.appendChild(document.createTextNode(styles))
        // document.body.appendChild(style)
        // return () => {
        //     document.body.removeChild(style)
        // }
    }, [theme])
    return (
        <RecoilRoot>
            <ConfigProvider locale={locales[data.language]}>
                {/* <QueryClientProvider client={queryClient}>
                    <useAppContext.Provider value={context}>
                        <App />
                    </useAppContext.Provider>
                </QueryClientProvider> */}
                <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
                    <useAppContext.Provider value={context}>
                        <App />
                    </useAppContext.Provider>
                </PersistQueryClientProvider>
            </ConfigProvider>
        </RecoilRoot>
    )
}

// @ts-ignore
ReactDOM.createRoot(document.getElementById('root')!).render(<Root />)
