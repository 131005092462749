import { useEffect } from "react";
const InitSiteName = window.siteName || ""

export const useTitle = (title, siteName = InitSiteName) => {
    return useEffect(() => {
        if (!title) {
            return
        }
        document.title = `${title}-${siteName}`
        // return () => {
        //     document.title = siteName
        // }
    }, [title])
}

export default useTitle