import fetch from '@/utils/fetch'
// 登录
export const login: any = async (data) => fetch.post('api/koa/login', data)

// 获取用户信息
export const getUserInfo: any = async () => fetch.get('api/koa/userinfo')

// 统计
export const getStatistics: any = (data) => fetch.get(`api/koa/monk/count/total`, data)

// 获取小说tags
export const getTags: any = async (params) =>
    fetch('api/koa/monk/books/getcats', { params }).then((res) => {
        return res.data.map((d) => {
            return { value: d, label: d }
        })
    })

// 反馈
export const postFeedBackAdd: any = (data) => fetch.post(`api/koa/monk/feedback/add`, data)
