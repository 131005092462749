import React, { Suspense } from "react";

const importCompont = (importCpt) => {
    const LazyComponent = React.lazy(importCpt);
    return (props) => {
        return <Suspense fallback={null}>
            <LazyComponent {...props} />
        </Suspense>
    }
}

export default importCompont