import importCompont from '@pn/components/src/importCompont'
import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

const Home = importCompont(() => import('./Home'))
const Books = importCompont(() => import('./Books'))
const BookDetail = importCompont(() => import('./Books/BookDetail'))
const BookContent = importCompont(() => import('./Books/BookContent'))
const Me = importCompont(() => import('./Me'))
const Login = importCompont(() => import('./Login'))
const Tasks = importCompont(() => import('./Tasks'))
const Test = importCompont(() => import('./Test'))

const Router = () => {
    const router = useRoutes([
        { path: '/', element: <Books /> },
        { path: '/books', element: <Home /> },
        { path: '/books/:bookId', element: <BookDetail /> },
        { path: '/books/:bookId/:id', element: <BookContent /> },
        { path: '/me', element: <Me /> },
        { path: '/test', element: <Test /> },
        { path: '/tasks', element: <Tasks /> },
        { path: '/login', element: <Login /> },
        { path: '*', element: <Navigate replace to={{ pathname: '/' }} /> },
    ])
    return router
}

export default Router
